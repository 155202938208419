//React imports
import React, { useState } from "react"
import * as Yup from "yup"
import { navigate } from "gatsby"

import Text from "../../atoms/text"
//import ReCAPTCHA from 'react-google-recaptcha';
import Helpers from "../../../setup/utils/helper"
import DynamicForms from "../../molecules/dynamic-forms"

//Styles
import "../../../../node_modules/react-phone-number-input/style.css"
import "./search-form.scss"

function SearchForm(props) {
  const initialValues = {
    whereTo: "all",
    adults: 1,
    kids: 0,
    checkIn: null,
    checkOut: null,
  }

  const [startDate, setStartDate] = useState(initialValues.checkIn)
  const [endDate, setEndDate] = useState(initialValues.checkOut)

  const fieldGroupWhere = {
    fieldGroup: [
      [
        {
          type: "select",
          name: "whereTo",
          label: "Where to",
          title: "Where To",
          className: "search_form__where",
          config: {
            list: props.placeList,
            defaultOption: true,
            defaultOptionValue: "all",
            defaultOptionName: "All destinations",
            defaultOptionIsDisabled: false,
          },
        },
      ],
    ],
  }

  const fieldGroupDates = {
    title: "Your travel dates",
    custom: {
      titleFont: "font_style__heading-5",
      titleColor: "color__cerulean-blue",
    },
    fieldGroup: [
      [
        {
          type: "date",
          name: "checkIn",
          label: "Dates",
          title: "Check In",
          className: "search_form__check_in",
          customOnChange: date => {
            setStartDate(date)
            if (date > endDate) {
              setEndDate(date)
            }
            onCheckInChange("search_form__check_out")
          },
          config: {
            selected: startDate,
            selectsStart: true,
            startDate: startDate,
            endDate: endDate,
            minDate: new Date(),
            maxDate: new Date(
              new Date().setFullYear(new Date().getFullYear() + 2)
            ),
            placeholderText: "Choose a date",
          },
        },
        {
          type: "date",
          name: "checkOut",
          label: "Dates",
          title: "Check Out",
          className: "search_form__check_out",
          customOnChange: date => setEndDate(date),
          config: {
            selected: endDate,
            selectsEnd: true,
            startDate: startDate,
            endDate: endDate,
            minDate: startDate,
            maxDate: new Date(
              new Date().setFullYear(new Date().getFullYear() + 2)
            ),
            placeholderText: "Choose a date",
          },
        },
      ],
    ],
  }

  const formDefinition = {
    fieldGroups: [fieldGroupWhere, fieldGroupDates, fieldGroupParty].filter(
      x => x !== null
    ),
    submitClickCallback: values => {
      let availUrl = `/search/?whereTo=${values.whereTo}`

      if (values.checkIn instanceof Date && values.checkOut instanceof Date) {
        availUrl += `&checkIn=${transformDate(
          values.checkIn
        )}&checkOut=${transformDate(values.checkOut)}`
      }

      availUrl += `&adults=${values.adults}&kids=${values.kids}`

      return navigate(availUrl)
    },
  }

  const transformDate = date => {
    let day = String(date.getDate()).padStart(2, "0")
    let month = String(date.getMonth() + 1).padStart(2, "0")
    let year = date.getFullYear()
    return year + month + day
  }
  const validationSchema = Yup.object().shape({
    whereTo: Yup.string().required("Required"),
    adults: Yup.number()
      .min(1, "At least 1 adult is required")
      .integer("The number must be an integer")
      .required("Required"),
    kids: Yup.number()
      .integer("The number must be an integer")
      .required("Required"),
  })

  const validate = async values => {
    const Options = {
      strict: false,
      abortEarly: false,
      stripUnknown: false,
      recursive: true,
    }

    let errors = {}
    errors = await validationSchema
      .validate(values, Options)
      .catch(function (err) {
        return Helpers.yupValidate(values, err)
      })

    if (errors === values) return {}
    return errors
  }

  return (
    <div className="search_form">
      <div className="search_form__container">
        <div className="search_form__title">
          <Text text="Find amazing places" font="font_style__heading-2"></Text>
        </div>
        <DynamicForms
          initialValues={initialValues}
          className="search_form__form"
          formDefinition={formDefinition}
          formClass="search_form"
          submitName="Look for properties"
          validate={values => validate(values)}
        />
      </div>
    </div>
  )
}

const fieldGroupParty = {
  title: "Your party",
  custom: {
    titleFont: "font_style__heading-5",
    titleColor: "color__cerulean-blue",
  },
  fieldGroup: [
    [
      {
        type: "guest",
        name: "adults",
        label: "Adults",
        title: "Adults",
        parentClassName: "search_form__adults",
      },
      {
        type: "guest",
        name: "kids",
        label: "Kids",
        title: "Kids",
        parentClassName: "search_form__kids",
      },
    ],
  ],
}

const onCheckInChange = checkoutClass => {
  const checkoutInput = document.getElementsByClassName(checkoutClass)
  if (checkoutInput.length === 1) {
    setTimeout(function () {
      checkoutInput[0].click()
    }, 100)
  }
}

export default SearchForm
